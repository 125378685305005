<template>
  <div>
    <chat-intent-item :intent="intent" @request-add-response="openResponseDialog"></chat-intent-item>
    <div>
      <chat-intent-item
        v-for="children in intent.children"
        :key="children.id"
        :intent="children"
        @request-add-response="openResponseDialog">
      </chat-intent-item>
    </div>
    <div class="mt-20 flex justify-center">
      <router-link :to="{ name: 'chat_intent__list' }" tag="a" class="button-outline-primary py-2 px-10 rounded-full text-sm mr-5">Back</router-link>
    </div>
    <select-response-dialog
      v-if="selectResponseDialog.visible"
      :intent="selectResponseDialog.intent"
      @close="selectResponseDialog.visible = false"
      @response-messages-updated="findIntent">
    </select-response-dialog>
  </div>
</template>

<script>
import ChatIntentItem from '@/components/ChatIntentItem'
import SelectResponseDialog from '@/components/SelectResponseDialog'

export default {
  name: 'ChatIntentConfig',

  components: {
    ChatIntentItem,
    SelectResponseDialog
  },

  data () {
    return {
      intent: {},
      selectResponseDialog: {
        visible: false,
        intent: null 
      }
    }
  },

  async created () {
    this.__showPageLoading()

    await this.findIntent()

    this.__hidePageLoading()
  },

  methods: {
    async findIntent () {
      try {
        const res = await this.$http.auth.get(`/bot/chat-intents/${this.$route.params.id}`)

        this.intent = res.data
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    openResponseDialog (intent) {
      this.selectResponseDialog.intent = intent
      this.selectResponseDialog.visible = true
    }
  }
}
</script>
