<template>
  <div>
    <div class="flex justify-between items-center mb-5">
      <div class="flex-initial">
        <h1 class="text-2xl" v-html="intent.fullname"></h1>
      </div>
      <div class="flex-initial">
        <button class="button-primary py-2 px-5 rounded-full text-sm" @click="addResponse">
          <i class="el-icon-s-tools"></i>
          <span class="ml-2">Manage Response Message</span>
        </button>
      </div>
    </div>
    <response-message v-for="response in intent.response_messages" :key="response.id" :response="response"></response-message>
  </div>
</template>

<script>
import ResponseMessage from '@/components/ResponseMessage'

export default {
  name: 'ChatIntentItem',

  components: {
    ResponseMessage
  },

  props: {
    intent: {
      required: true,
      default () {
        return {}
      }
    }
  },

  methods: {
    addResponse () {
      this.$emit('request-add-response', this.intent)
    }
  }
}
</script>
