import auth from './routes/auth'
import user from './routes/user'
import role from './routes/role'
import chatIntent from './routes/chat-intent'
import contactUs from './routes/contact-us'
import entryMessage from './routes/entry-message'
import event from './routes/event'
import hotDeal from './routes/hot-deal'
import promotion from './routes/promotion'
import responseMessage from './routes/response-message'
import subscription from './routes/subscription'
import subscriptionPackage from './routes/subscription-package'
import botSimulator from './routes/bot-simulator'

import AdminLayout from '@/views/layouts/Admin'

const routes = [
  {
    path: '/',
    component: AdminLayout,
    children: [
      ...user,
      ...role,
      ...chatIntent,
      ...contactUs,
      ...entryMessage,
      ...event,
      ...hotDeal,
      ...promotion,
      ...responseMessage,
      ...subscription,
      ...subscriptionPackage,
      ...botSimulator
    ]
  },
  ...auth
]

export default routes
