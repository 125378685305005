<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">{{ page.title }}</h1>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <form>
        <div class="form-item">
          <label class="form-item__label">Name</label>
          <el-input v-model="input.name" name="name" v-validate="rules.name" placeholder="Name"></el-input>
          <p class="form-item__error">{{ errors.first('name') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Display name</label>
          <el-input v-model="input.display_name" name="display_name" v-validate="rules.display_name" placeholder="Display Name"></el-input>
          <p class="form-item__error">{{ errors.first('display_name') }}</p>
        </div>
      </form>
      <div class="mt-10 flex justify-end">
        <router-link :to="{ name: 'role__list' }" tag="a" class="button-outline-primary py-2 px-10 rounded-full text-sm mr-5">Cancel</router-link>
        <button class="button-primary py-2 px-10 rounded-full text-sm" @click="save">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      page: {
        title: 'Create Role',
        params: {},
        editing: false
      },
      input: {
        name: '',
        display_name: '',
      },
      rules: {
        name: 'required',
        display_name: 'required',
      }
    }
  },

  async created () {
    if (this.$route.name === 'role__edit') {
      this.page.title = 'Edit Role'
      this.page.params = this.$route.params
      this.page.editing = true

      this.__showPageLoading()

      await this.findRole()

      this.__hidePageLoading()
    }
  },

  methods: {
    async findRole () {
      try {
        const res = await this.$http.auth.get(`/roles/${this.page.params.id}`)

        this.input.name = res.data.name
        this.input.display_name = res.data.display_name
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async create () {
      try {
        const res = await this.$http.auth.post('/roles', this.input)

        this.__httpSuccessRedirect(this, res.data, {
          name: 'role__permission',
          params: { id: res.data.data.id }
        })
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async update () {
      try {
        const res = await this.$http.auth.put(`/roles/${this.page.params.id}`, this.input)

        this.__httpSuccessRedirect(this, res.data, 'role__list')
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    save () {
      this.$validator.validate().then(async valid => {
        if (!valid) return false 

        this.__showPageLoading()

        if (this.page.editing) {
          await this.update()
        } else {
          await this.create()
        }

        this.__hidePageLoading()

      })
    }
  }
}
</script>