<template>
  <div id="app" class="bg-gray-200 min-h-screen">
    <router-view></router-view>
    <page-loading></page-loading>
  </div>
</template>

<script>
import PageLoading from '@/components/loading/PageLoading'

export default {
  name: 'App',

  components: {
    PageLoading
  }
}
</script>