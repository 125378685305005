<template>
  <div class="bg-white shadow-md rounded my-5 p-5">
    <div class="flex -m-2">
      <div class="w-1/2 p-2">
        <div class="form-item">
          <label class="form-item__label">Entry Message</label>
          <el-autocomplete
            class="inline-input"
            v-model="input.searchEntryMessage"
            :fetch-suggestions="searchEntryMessages"
            placeholder="Type to search..."
            @select="onEntryMessageSelected">
            <template slot-scope="{ item }">
              <div class="value">message : {{ item.message }}</div>
              <span class="link">type : {{ item.type }}</span>
            </template>
          </el-autocomplete>
        </div>
        <div class="mt-10">
          <div class="flex flex-wrap -m-2 justify-center">
            <div class="text-center">
              <button
                v-for="entry in response.entry_messages"
                :key="entry.id"
                class="bg-pink-500 m-1 py-1 rounded-full text-white text-sm overflow-hidden focus:outline-none"
                @click="removeEntryMessage(entry)">
                <span class="px-5 font-bold">{{ entry.message }}</span>
                <span class="bg-pink-700 py-2 px-3">
                  <i class="el-icon-close font-semibold text-white"></i>
                </span>
              </button>
              <p v-if="errors.has('entry_messages') && response.entry_messages.length == 0" class="form-item__error">{{ errors.first('entry_messages') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/2 p-2">
        <div class="form-item">
          <label class="form-item__label">Type</label>
          <el-select v-model="response.type">
            <el-option
              v-for="option in responseTypeOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value">
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <label class="form-item__label">Response Message</label>
          <el-input v-if="response.type === 'text'" v-model="response.message" type="textarea" rows="10"></el-input>
          <el-input v-else-if="response.type === 'script'" v-model="response.message"></el-input>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button class="button-primary py-2 px-10 rounded-full text-sm" @click="update">Update</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResponseMessage',

  props: {
    response: {
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      input: {
        searchEntryMessage: ''
      },
      responseTypeOptions: [
        {
          label: 'Text',
          value: 'text'
        },
        {
          label: 'Script',
          value: 'script'
        }
      ]
    }
  },

  methods: {
    async searchEntryMessages (queryString, cb) {
      try {
        const res = await this.$http.auth.get('/bot/entry-messages', {
          params: {
            search: queryString,
            except: this.response.entry_messages.map(item => item.id)
          }
        })

        cb(res.data.data)
      } catch (err) {
        this.__httpError(this, err)

        cb([])
      }
    },
    async update () {
      try {
        const data = {
          type: this.response.type,
          message: this.response.message,
          entry_messages: this.response.entry_messages.map(entry => entry.id)
        }

        const res = await this.$http.auth.put(`/bot/response-messages/${this.response.id}/entry-messages`, data)

        this.__httpSuccess(this, res.data)
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    onEntryMessageSelected (item) {
      this.input.searchEntryMessage = ''
      this.response.entry_messages.push(item)
    },
    removeEntryMessage (item) {
      this.response.entry_messages = this.response.entry_messages.filter(entry => {
        return entry.id !== item.id
      })
    }
  }
}
</script>
