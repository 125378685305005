import Main from '@/views/event/Index'
import Create from '@/views/event/Create'

export default [
  {
    path: '/events',
    name: 'event__list',
    component: Main
  },
  {
    path: '/events/create',
    name: 'event__create',
    component: Create
  },
  {
    path: '/events/:id/edit',
    name: 'event__edit',
    component: Create
  },
]
