import List from '@/views/role/Index'
import Input from '@/views/role/Create'
import Permission from '@/views/role/Permission'

export default [
  {
    path: '/roles',
    name: 'role__list',
    component: List
  },
  {
    path: '/roles/create',
    name: 'role__create',
    component: Input
  },
  {
    path: '/roles/:id/edit',
    name: 'role__edit',
    component: Input
  },
  {
    path: '/roles/:id/permissions',
    name: 'role__permission',
    component: Permission
  }
]
