import Main from '@/views/entry-message/Index'
import Create from '@/views/entry-message/Create'

export default [
  {
    path: '/bot/entry-messages',
    name: 'entry_message__list',
    component: Main
  },
  {
    path: '/bot/entry-messages/create',
    name: 'entry_message__create',
    component: Create
  },
  {
    path: '/bot/entry-messages/:id/edit',
    name: 'entry_message__edit',
    component: Create
  },
]
