<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">{{ page.title }}</h1>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <form>
        <div class="form-item">
          <label class="form-item__label">Type</label>
          <el-select v-model="input.type" name="type" v-validate="rules.type" :readonly="input.hasSubscriptions === 1">
            <el-option value="hot-deal" label="Hot Deal"></el-option>
          </el-select>
          <p class="form-item__error">{{ errors.first('type') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Name</label>
          <el-input v-model="input.name" name="name" v-validate="rules.name" placeholder="Name" :readonly="input.hasSubscriptions === 1"></el-input>
          <p class="form-item__error">{{ errors.first('name') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Duration</label>
          <div>
            <el-input
              v-model="input.durationValue"
              class="input-with-select"
              name="duration"
              v-validate="rules.duration"
              type="number"
              min="0"
              placeholder="Duration"
              :readonly="input.hasSubscriptions === 1"
              @change="updateDuration">
              <span v-if="input.hasSubscriptions === 1" slot="append">
                {{ options.duration[input.durationType] }}
              </span>
              <el-select v-else v-model="input.durationType" slot="append" @change="updateDuration">
                <el-option v-for="(val, key) in options.duration" :key="key" :value="key" :label="val"></el-option>
              </el-select>
            </el-input>
          </div>
          <p class="form-item__error">{{ errors.first('duration') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Price</label>
          <div>
            <el-input
              v-model="input.price"
              name="price"
              v-validate="rules.price"
              type="number"
              min="0"
              placeholder="Duration">
              <template slot="append">{{ __idrCurrency(input.price) }}</template>
            </el-input>
          </div>
          <p class="form-item__error">{{ errors.first('price') }}</p>
        </div>
      </form>
      <div class="mt-10 flex items-center" :class="[this.page.editing ? 'justify-between' : 'justify-end']">
        <div v-if="this.page.editing">
          <el-switch
            v-model="input.active"
            :active-value="1"
            :inactive-value="0"
            active-color="#ed64a6">
          </el-switch>
        </div>
        <div>
          <router-link :to="{ name: 'subscription_package__list' }" tag="a" class="button-outline-primary py-2 px-10 rounded-full text-sm mr-5">Cancel</router-link>
          <button class="button-primary py-2 px-10 rounded-full text-sm" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      page: {
        title: 'Create Subscription Package',
        params: {},
        editing: false
      },
      input: {
        type: 'hot-deal',
        name: '',
        duration: '',
        durationValue: '',
        durationType: 'M',
        price: 0,
        active: 1,
        hasSubscriptions: 0
      },
      rules: {
        type: 'required',
        name: 'required',
        duration: 'required',
        price: 'required'
      },
      options: {
        duration: {
          D: 'Day',
          M: 'Month',
          Y: 'Year'
        }
      }
    }
  },

  async created () {
    if (this.$route.name === 'subscription_package__edit') {
      this.page.title = 'Edit Subscription Package'
      this.page.params = this.$route.params
      this.page.editing = true

      this.__showPageLoading()

      await this.findSubscriptionPackage()

      this.__hidePageLoading()
    }
  },

  methods: {
    async findSubscriptionPackage () {
      try {
        const res = await this.$http.auth.get(`/subscription-packages/${this.page.params.id}`)

        this.input.type = res.data.type
        this.input.name = res.data.name
        this.input.durationValue = res.data.duration.split(' ')[0]
        this.input.durationType = res.data.duration.split(' ')[1]
        this.input.duration = this.input.durationValue +' '+ this.input.durationType
        this.input.price = res.data.price
        this.input.active = res.data.active
        this.input.hasSubscriptions = res.data.has_subscriptions
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async create () {
      try {
        const res = await this.$http.auth.post('/subscription-packages', this.input)

        this.__httpSuccessRedirect(this, res.data, 'subscription_package__list')
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async update () {
      try {
        const res = await this.$http.auth.put(`/subscription-packages/${this.page.params.id}`, this.input)

        this.__httpSuccessRedirect(this, res.data, 'subscription_package__list')
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    save () {
      this.$validator.validate().then(async valid => {
        if (!valid) return false 

        this.__showPageLoading()

        if (this.page.editing) {
          await this.update()
        } else {
          await this.create()
        }

        this.__hidePageLoading()

      })
    },
    updateDuration () {
      this.input.duration = this.input.durationValue +' '+ this.input.durationType
    }
  }
}
</script>

<style scoped>
  .el-input.input-with-select {
    width: 250px;
  }

  .el-input.input-with-select .el-select {
    width: 100px;
  }
</style>
