import List from '@/views/user/Index'
import Input from '@/views/user/Create'

export default [
  {
    path: '/users',
    name: 'user__list',
    component: List
  },
  {
    path: '/users/create',
    name: 'user__create',
    component: Input
  },
  {
    path: '/users/:id/edit',
    name: 'user__edit',
    component: Input
  }
]
