<template>
  <div v-if="pageIsLoading" class="page-loading">
    <div class="lds-css ng-scope"><div style="width:100%;height:100%" class="lds-ripple"><div></div><div></div></div></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PageLoading',

  computed: {
    ...mapGetters('page', [
      'pageIsLoading'
    ])
  }
}
</script>

<style scoped>
  .page-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes lds-ripple {
    0% {
      top: 90px;
      left: 90px;
      width: 0;
      height: 0;
      opacity: 1;
    }

    100% {
      top: 35px;
      left: 35px;
      width: 110px;
      height: 110px;
      opacity: 0;
    }
  }

  @-webkit-keyframes lds-ripple {
    0% {
      top: 90px;
      left: 90px;
      width: 0;
      height: 0;
      opacity: 1;
    }

    100% {
      top: 35px;
      left: 35px;
      width: 110px;
      height: 110px;
      opacity: 0;
    }
  }

  .lds-ripple {
    position: relative;
  }

  .lds-ripple div {
    box-sizing: content-box;
    position: absolute;
    border-width: 10px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .lds-ripple div:nth-child(1) {
    border-color: #ffb6bb;
  }

  .lds-ripple div:nth-child(2) {
    border-color: #ffe691;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  .lds-ripple {
    width: 200px !important;
    height: 200px !important;
    -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  }
</style>