import Vue from 'vue'

import storage from './storage'

Vue.auth = () => {
  return {
    store (auth) {
      storage.set('auth', auth)
    },
    token () {
      let auth = storage.get('auth')

      if (auth === null || typeof auth === 'undefined' || auth.length === 0) return null

      return auth.access_token
    },
    destroy () {
      storage.clear()
    }
  }
}

Object.defineProperties(Vue.prototype, {
  $auth: {
    get () { return Vue.auth() }
  }
})
