<template>
  <li class="dropdown">
    <a href="#" @click.prevent="isOpen = !isOpen"><slot></slot></a>
    <button v-if="isOpen" class="dropdown-container" @click="isOpen = false"></button>
    <div v-if="isOpen" class="dropdown-nav" :class="positions[position]">
      <slot name="menu"></slot>
    </div>
  </li>
</template>

<script>
export default {
  name: 'Dropdown',

  props: {
    position: {
      default: 'left'
    }
  },

  data () {
    return {
      isOpen: false,
      positions: {
        left: 'left-0',
        right: 'right-0'
      }
    }
  }
}
</script>