<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">Subscription Packages</h1>
      <router-link
        :to="{ name: 'subscription_package__create' }"
        tag="button"
        class="button-primary py-2 px-10 rounded-full text-sm">
        New
      </router-link>
    </div>
    <div class="bg-white shadow-md rounded my-5 p-5 flex justify-between">
      <div class="w-2/12">
        <el-select v-model="filter.sort" @change="onSort">
          <el-option
            v-for="(sort, key) in sortOptions"
            :key="key"
            :label="sort.label"
            :value="sort.value">
          </el-option>
        </el-select>
      </div>
      <div class="w-3/12">
        <el-input
          v-model="filter.search"
          placeholder="Search..."
          prefix-icon="el-icon-search"
          :clearable="true" @input="onSearch">
        </el-input>
      </div>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <div>
        <el-table :data="roles">
          <el-table-column
            prop="name"
            label="Name">
          </el-table-column>
          <el-table-column
            prop="type"
            label="Type">
          </el-table-column>
          <el-table-column
            prop="duration_text"
            label="Duration">
          </el-table-column>
          <el-table-column
            prop="price"
            label="Price">
            <template slot-scope="scope">{{ __idrCurrency(scope.row.price) }}</template>
          </el-table-column>
          <el-table-column
            label="Status"
            align="center"
            width="150">
            <template slot-scope="scope">
              <status-label :active="scope.row.active"></status-label>
            </template>
          </el-table-column>
          <el-table-column
            label="Actions"
            width="100"
            align="center">
            <template slot-scope="scope">
              <router-link
                :to="{ name: 'subscription_package__edit', params: { id: scope.row.id } }"
                tag="button"
                class="ml-2 border-solid border border-gray-300 px-5 rounded text-sm focus:outline-none">
                Edit
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center pt-5">
          <el-pagination
            layout="prev, pager, next"
            :current-page="pagination.current_page"
            :page-size="pagination.per_page"
            :total="pagination.total"
            @current-change="onPageChanged">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import StatusLabel from '@/components/StatusLabel'

export default {
  name: 'RoleList',

  components: {
    StatusLabel
  },

  data () {
    return {
      roles: [],
      pagination: {
        current_page: 1,
        per_page: 25,
        total: 0
      },
      filter: {
        sort: 'name,asc',
        search: ''
      },
      sortOptions: [
        {
          label: 'Name (a-z)',
          value: 'name,asc'
        },
        {
          label: 'Name (z-a)',
          value: 'name,desc'
        },
        {
          label: 'Type (a-z)',
          value: 'type,asc'
        },
        {
          label: 'Type (z-a)',
          value: 'type,desc'
        },
        {
          label: 'Duration (short-long)',
          value: 'duration,asc'
        },
        {
          label: 'Duration (long-short)',
          value: 'duration,desc'
        },
        {
          label: 'Price (low-hight)',
          value: 'price,asc'
        },
        {
          label: 'Price (hight-low)',
          value: 'price,desc'
        },
      ]
    }
  },

  async created () {
    this.__showPageLoading()

    await this.getSubscriptionPackages()

    this.__hidePageLoading()
  },

  methods: {
    async getSubscriptionPackages () {
      try {
        const res = await this.$http.auth.get('/subscription-packages', {
          params: {
            page: this.pagination.current_page,
            search: this.filter.search,
            sort: this.filter.sort.split(',')
          }
        })

        this.roles = res.data.data
        this.pagination = res.data.pagination
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    onSort() {
      this.pagination.current_page = 1

      this.getSubscriptionPackages()
    },
    onSearch: _.debounce(function () {
      this.pagination.current_page = 1

      this.getSubscriptionPackages()
    }, 1000),
    onPageChanged (page) {
      this.pagination.current_page = page

      this.getSubscriptionPackages()
    }
  }
}
</script>
