<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">{{ page.title }}</h1>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <form>
        <div class="form-item">
          <label class="form-item__label">Name</label>
          <el-input v-model="input.name" name="name" v-validate="rules.name" placeholder="Name"></el-input>
          <p class="form-item__error">{{ errors.first('name') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Email</label>
          <el-input v-model="input.email" name="email" v-validate="rules.email" placeholder="Email"></el-input>
          <p class="form-item__error">{{ errors.first('email') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Password</label>
          <el-input v-model="input.password" name="password" v-validate="rules.password" type="password" :placeholder="page.editing ? 'Leave blank if not change' : 'Password'"></el-input>
          <p class="form-item__error">{{ errors.first('password') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Role</label>
          <el-select v-model="input.role">
            <el-option label="None" :value="null"></el-option>
            <el-option
              v-for="(role, key) in roles"
              :key="key"
              :label="role.display_name"
              :value="role.id">
            </el-option>
          </el-select>
        </div>
      </form>
      <div class="mt-10 flex justify-end">
        <router-link :to="{ name: 'user__list' }" tag="a" class="button-outline-primary py-2 px-10 rounded-full text-sm mr-5">Cancel</router-link>
        <button class="button-primary py-2 px-10 rounded-full text-sm" @click="save">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      page: {
        title: 'Create User',
        params: {},
        editing: false
      },
      input: {
        name: '',
        email: '',
        password: '',
        role: null
      },
      rules: {
        name: 'required',
        email: 'required',
        password: 'required|min:6'
      },
      roles: []
    }
  },

  async created () {
    this.__showPageLoading()

    await this.getRoles()

    if (this.$route.name === 'user__edit') {
      this.page.title = 'Edit User'
      this.page.params = this.$route.params
      this.page.editing = true

      await this.findUser()
    }

    this.__hidePageLoading()
  },

  methods: {
    async getRoles () {
      try {
        const res = await this.$http.auth.get('/roles/all', this.input)

        this.roles = res.data
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async findUser () {
      try {
        const res = await this.$http.auth.get(`/users/${this.page.params.id}`)

        this.input.name = res.data.name
        this.input.email = res.data.email
        this.input.role = res.data.role_id
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async create () {
      try {
        const res = await this.$http.auth.post('/users', this.input)

        this.__httpSuccessRedirect(this, res.data, 'user__list')
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async update () {
      try {
        const res = await this.$http.auth.put(`/users/${this.page.params.id}`, this.input)

        this.__httpSuccessRedirect(this, res.data, 'user__list')
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    save () {
      if (this.page.editing && this.input.password.length > 0) {
        this.$validator.attach('password')
      } else {
        this.$validator.detach('password')
      }

      this.$validator.validate().then(async valid => {
        if (!valid) return false 

        this.__showPageLoading()

        if (this.page.editing) {
          await this.update()
        } else {
          await this.create()
        }

        this.__hidePageLoading()

      })
    }
  }
}
</script>