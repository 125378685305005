<template>
  <div class="fixed left-0 top-0 bg-white min-h-screen min-w-full z-50 flex justify-center">
    <div class="container">
      <div class="flex justify-between items-center pt-5 pb-10">
        <div class="flex-initial">
          <h1 class="text-2xl" v-html="intent.fullname"></h1>
          <div class="mt-2 inline-block text-pink-500 font-semibold">Add Response Message</div>
        </div>
        <div class="flex-initial">
          <button class="button-outline-primary py-1 px-2 rounded-full text-sm" @click="close">
            <i class="el-icon-close font-bold"></i>
          </button>
        </div>
      </div>
      <div class="flex -m-5">
        <div class="w-1/3 p-5">
          <h3 class="text-l font-semibold">Create New</h3>
          <div class="form-item">
            <label class="form-item__label">Type</label>
            <el-select v-model="input.type">
              <el-option
                v-for="option in responseTypeOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value">
              </el-option>
            </el-select>
            <p class="form-item__error">{{ errors.first('type') }}</p>
          </div>
          <div class="form-item">
            <label class="form-item__label">Response Message</label>
            <el-input v-if="input.type === 'text'" v-model="input.message" type="textarea" rows="10"></el-input>
            <el-input v-else-if="input.type === 'script'" v-model="input.message"></el-input>
            <p class="form-item__error">{{ errors.first('message') }}</p>
          </div>
          <div class="text-right">
            <button class="button-primary py-2 px-10 rounded-full text-sm" @click="saveResponseMessage">Save</button>
          </div>
        </div>
        <div class="w-2/3 p-5">
          <h3 class="text-l mb-5 font-semibold">Available Response Messages</h3>
          <el-table :data="responseMessages">
            <el-table-column
              prop="type"
              label="Type"
              width="100">
            </el-table-column>
            <el-table-column
              prop="message"
              label="Message">
            </el-table-column>
            <el-table-column
              label="Actions"
              align="center"
              width="150">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.intent_id"
                  :active-value="intent.id"
                  :inactive-value="null"
                  active-color="#ed64a6"
                  @change="selectResponse(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    intent: {
      required: true,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      responseMessages: [],
      input: {
        type: 'text',
        message: ''
      },
      responseTypeOptions: [
        {
          label: 'Text',
          value: 'text'
        },
        {
          label: 'Script',
          value: 'script'
        }
      ]
    }
  },

  created () {
    this.getResponseMessges()
  },

  methods: {
    async getResponseMessges () {
      try {
        const res = await this.$http.auth.get(`/bot/response-messages`, {
          params: {
            intent: this.intent.id
          }
        })

        this.responseMessages = res.data.data
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async saveResponseMessage () {
      this.__showPageLoading()

      try {
        const res = await this.$http.auth.post(`/bot/response-messages`, this.input)

        this.input.type = 'text'
        this.input.message = ''

        this.getResponseMessges()

        this.__hidePageLoading()

        this.__httpSuccess(this, res.data)
      } catch (err) {
        this.__hidePageLoading()

        this.__httpError(this, err)
      }
    },
    async selectResponse (responseMessage) {
      try {
        const res = await this.$http.auth.put(`/bot/response-messages/${responseMessage.id}`, responseMessage)

        this.getResponseMessges()

        this.__httpSuccess(this, res.data)

        this.$emit('response-messages-updated');
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
