<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">Roles</h1>
      <router-link
        :to="{ name: 'role__create' }"
        tag="button"
        class="button-primary py-2 px-10 rounded-full text-sm">
        New
      </router-link>
    </div>
    <div class="bg-white shadow-md rounded my-5 p-5 flex justify-between">
      <div class="w-2/12">
        <el-select v-model="filter.sort" @change="onSort">
          <el-option
            v-for="(sort, key) in sortOptions"
            :key="key"
            :label="sort.label"
            :value="sort.value">
          </el-option>
        </el-select>
      </div>
      <div class="w-3/12">
        <el-input
          v-model="filter.search"
          placeholder="Search..."
          prefix-icon="el-icon-search"
          :clearable="true" @input="onSearch">
        </el-input>
      </div>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <div>
        <el-table :data="roles">
          <el-table-column
            prop="name"
            label="Name">
          </el-table-column>
          <el-table-column
            prop="display_name"
            label="Display Name">
          </el-table-column>
          <el-table-column
            label="Actions"
            width="300"
            align="center">
            <template slot-scope="scope">
              <router-link
                :to="{ name: 'role__permission', params: { id: scope.row.id } }"
                tag="button"
                class="border-solid border border-gray-300 px-5 rounded text-sm focus:outline-none">
                Manage Permissions
              </router-link>
              <router-link
                :to="{ name: 'role__edit', params: { id: scope.row.id } }"
                tag="button"
                class="ml-2 border-solid border border-gray-300 px-5 rounded text-sm focus:outline-none">
                Edit
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center pt-5">
          <el-pagination
            layout="prev, pager, next"
            :current-page="pagination.current_page"
            :page-size="pagination.per_page"
            :total="pagination.total"
            @current-change="onPageChanged">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'RoleList',

  data () {
    return {
      roles: [],
      pagination: {
        current_page: 1,
        per_page: 25,
        total: 0
      },
      filter: {
        sort: 'name,asc',
        search: ''
      },
      sortOptions: [
        {
          label: 'Name (a-z)',
          value: 'name,asc'
        },
        {
          label: 'Name (z-a)',
          value: 'name,desc'
        },
        {
          label: 'Display Name (a-z)',
          value: 'display_name,asc'
        },
        {
          label: 'Display Name (z-a)',
          value: 'display_name,desc'
        }
      ]
    }
  },

  async created () {
    this.__showPageLoading()

    await this.getRoles()

    this.__hidePageLoading()
  },

  methods: {
    async getRoles () {
      try {
        const res = await this.$http.auth.get('/roles', {
          params: {
            page: this.pagination.current_page,
            search: this.filter.search,
            sort: this.filter.sort.split(',')
          }
        })

        this.roles = res.data.data
        this.pagination = res.data.pagination
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    onSort() {
      this.pagination.current_page = 1

      this.getRoles()
    },
    onSearch: _.debounce(function () {
      this.pagination.current_page = 1

      this.getRoles()
    }, 1000),
    onPageChanged (page) {
      this.pagination.current_page = page

      this.getRoles()
    }
  }
}
</script>
