import List from '@/views/subscription-package/Index'
import Input from '@/views/subscription-package/Create'

export default [
  {
    path: '/subscription-packages',
    name: 'subscription_package__list',
    component: List
  },
  {
    path: '/subscription-packages/create',
    name: 'subscription_package__create',
    component: Input
  },
  {
    path: '/subscription-packages/:id/edit',
    name: 'subscription_package__edit',
    component: Input
  }
]
