import Main from '@/views/promotion/Index'
import Create from '@/views/promotion/Create'

export default [
  {
    path: '/promotions',
    name: 'promotion__list',
    component: Main
  },
  {
    path: '/promotions/create',
    name: 'promotion__create',
    component: Create
  },
  {
    path: '/promotions/:id/edit',
    name: 'promotion__edit',
    component: Create
  },
]
