import Vue from 'vue'
import VeeValidate from 'vee-validate'

import App from './App.vue'

import router from '@/router'
import store from '@/store'

import '@/lib'
import '@/mixin'
import '@/themes/default'

Vue.use(VeeValidate, {
  events: ''
})

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
