<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">Contact Us</h1>
    </div>
    <div class="bg-white shadow-md rounded my-5 p-5 flex justify-between">
      <div class="w-2/12">
        <el-select v-model="filter.sort" @change="onSort">
          <el-option
            v-for="(sort, key) in sortOptions"
            :key="key"
            :label="sort.label"
            :value="sort.value">
          </el-option>
        </el-select>
      </div>
      <div class="w-3/12">
        <el-input
          v-model="filter.search"
          placeholder="Search..."
          prefix-icon="el-icon-search"
          :clearable="true" @input="onSearch">
        </el-input>
      </div>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <div>
        <el-table :data="contactUs">
          <el-table-column
            label="YUKK ID"
            prop="yukk_id"
            width="200">
          </el-table-column>
          <el-table-column
            label="Subject"
            prop="subject"
            width="200">
          </el-table-column>
          <el-table-column
            label="Message"
            prop="message">
          </el-table-column>
          <el-table-column
            label="Phone"
            prop="phone"
            width="200">
          </el-table-column>
        </el-table>
        <div class="text-center pt-5">
          <el-pagination
            layout="prev, pager, next"
            :current-page="pagination.current_page"
            :page-size="pagination.per_page"
            :total="pagination.total"
            @current-change="onPageChanged">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ContactUsList',

  data () {
    return {
      contactUs: [],
      pagination: {
        current_page: 1,
        per_page: 25,
        total: 0
      },
      filter: {
        sort: 'created_at,desc',
        search: ''
      },
      sortOptions: [
        {
          label: 'Created date (asc)',
          value: 'created_at,asc'
        },
        {
          label: 'Created date (desc)',
          value: 'created_at,desc'
        },
      ]
    }
  },

  async created () {
    this.__showPageLoading()

    await this.getContactUs()

    this.__hidePageLoading()
  },

  methods: {
    async getContactUs () {
      try {
        const res = await this.$http.auth.get('/contact-us', {
          params: {
            page: this.pagination.current_page,
            search: this.filter.search,
            sort: this.filter.sort.split(',')
          }
        })

        this.contactUs = res.data.data
        this.pagination = res.data.pagination
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    onSort() {
      this.pagination.current_page = 1

      this.getContactUs()
    },
    onSearch: _.debounce(function () {
      this.pagination.current_page = 1

      this.getContactUs()
    }, 1000),
    onPageChanged (page) {
      this.pagination.current_page = page

      this.getContactUs()
    }
  }
}
</script>
