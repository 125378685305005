<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">Subscription Detail</h1>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <div class="flex">
        <div class="w-1/2">
          <h3 class="text-xl">Subscription Info</h3>
          <div class="form-item">
            <label class="form-item__label">Mechant Branch</label>
            <div>{{ subscription.merchant_branch.name }}</div>
          </div>
          <div class="form-item">
            <label class="form-item__label">Package</label>
            <div>{{ subscription.package.name }}</div>
          </div>
          <div class="form-item">
            <label class="form-item__label">Price</label>
            <div>{{ __idrCurrency(subscription.price) }}</div>
          </div>
          <div class="form-item">
            <label class="form-item__label">Start Tine</label>
            <div>{{ subscription.start_time }}</div>
          </div>
          <div class="form-item">
            <label class="form-item__label">End Time</label>
            <div>{{ subscription.end_time }}</div>
          </div>
          <div class="form-item">
            <label class="form-item__label">Time Remaining</label>
            <div>{{ subscription.time_remaining_text }}</div>
          </div>
          <div class="form-item">
            <label class="form-item__label">Status</label>
            <div>
              <status-label :active="subscription.active"></status-label>
            </div>
          </div>
        </div>
        <div class="w-1/2">
          <h3 class="text-xl">Item Info</h3>
          <div class="form-item">
            <label class="form-item__label">Name</label>
            <div>{{ subscription.item.name }}</div>
          </div>
          <div class="form-item">
            <label class="form-item__label">Desccription</label>
            <div>{{ subscription.item.description }}</div>
          </div>
          <div class="form-item">
            <label class="form-item__label">File</label>
            <div>
              <a class="text-pink-500" :href="subscription.item.file_url" target="_blank">{{ subscription.item.file }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10 flex items-center">
        <div>
          <el-switch
            v-model="subscription.active"
            :active-value="1"
            :inactive-value="0"
            active-color="#ed64a6"
            @change="updateStatus">
          </el-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusLabel from '@/components/StatusLabel'

export default {
  name: 'SubscriptionShow',

  components: {
    StatusLabel
  },

  data () {
    return {
      subscription: {
        active: 0,
        merchant_branch: {},
        package: {},
        item: {}
      }
    }
  },

  async created () {
    this.__showPageLoading()

    await this.findSubscription()

    this.__hidePageLoading()
  },

  methods: {
    async findSubscription () {
      try {
        const res = await this.$http.auth.get(`/subscriptions/${this.$route.params.id}`)

        this.subscription = res.data
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async updateStatus (val) {
      this.__showPageLoading()

      try {
        const res = await this.$http.auth.put(`/subscriptions/${this.$route.params.id}/status`, {
          active: val
        })

        this.subscription = res.data.data

        this.__hidePageLoading()

        this.__httpSuccess(this, res.data)
      } catch (err) {
        this.__hidePageLoading()

        this.__httpError(this, err)
      }
    }
  }
}
</script>