<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">Subscriptions</h1>
    </div>
    <div class="bg-white shadow-md rounded my-5 p-5 flex justify-between">
      <div class="w-2/12">
        <el-select v-model="filter.sort" @change="onSort">
          <el-option
            v-for="(sort, key) in sortOptions"
            :key="key"
            :label="sort.label"
            :value="sort.value">
          </el-option>
        </el-select>
      </div>
      <div class="w-3/12">
        <el-input
          v-model="filter.search"
          placeholder="Search..."
          prefix-icon="el-icon-search"
          :clearable="true" @input="onSearch">
        </el-input>
      </div>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <div>
        <el-table :data="subscriptions">
          <el-table-column
            label="Merchant Branch"
            prop="merchant_branch.name">
          </el-table-column>
          <el-table-column
            label="Package"
            prop="package.name">
          </el-table-column>
          <el-table-column
            label="Price">
            <template slot-scope="scope">{{ __idrCurrency(scope.row.price) }}</template>
          </el-table-column>
          <el-table-column
            label="Time Remaining"
            prop="time_remaining_text"
            align="center">
          </el-table-column>
          <el-table-column
            label="Status"
            align="center"
            width="150">
            <template slot-scope="scope">
              <status-label :active="scope.row.active"></status-label>
            </template>
          </el-table-column>
          <el-table-column
            label="Actions"
            width="150"
            align="center">
            <template slot-scope="scope">
              <router-link
                :to="{ name: 'subscription__show', params: { id: scope.row.id } }"
                tag="button"
                class="ml-2 border-solid border border-gray-300 px-5 rounded text-sm focus:outline-none">
                Detail
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center pt-5">
          <el-pagination
            layout="prev, pager, next"
            :current-page="pagination.current_page"
            :page-size="pagination.per_page"
            :total="pagination.total"
            @current-change="onPageChanged">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import StatusLabel from '@/components/StatusLabel'

export default {
  name: 'SubscriptionList',

  components: {
    StatusLabel
  },

  data () {
    return {
      subscriptions: [],
      pagination: {
        current_page: 1,
        per_page: 25,
        total: 0
      },
      filter: {
        sort: 'time_remaining,asc',
        search: ''
      },
      sortOptions: [
        {
          label: 'Merchant Branch - Asc',
          value: 'merchant_branch,asc'
        },
        {
          label: 'Merchant Branch - Desc',
          value: 'merchant_branch,desc'
        },
        {
          label: 'Package - Asc',
          value: 'package,asc'
        },
        {
          label: 'Package - Desc',
          value: 'package,desc'
        },
        {
          label: 'Price - Asc',
          value: 'price,asc'
        },
        {
          label: 'Price - Desc',
          value: 'price,desc'
        },
        {
          label: 'Time Remaining - Asc',
          value: 'time_remaining,asc'
        },
        {
          label: 'Time Remaining - Desc',
          value: 'time_remaining,desc'
        }
      ]
    }
  },

  async created () {
    this.__showPageLoading()

    await this.getSubscriptions()

    this.__hidePageLoading()
  },

  methods: {
    async getSubscriptions () {
      try {
        const res = await this.$http.auth.get('/subscriptions', {
          params: {
            page: this.pagination.current_page,
            search: this.filter.search,
            sort: this.filter.sort.split(',')
          }
        })

        this.subscriptions = res.data.data
        this.pagination = res.data.pagination
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    onSort() {
      this.pagination.current_page = 1

      this.getSubscriptions()
    },
    onSearch: _.debounce(function () {
      this.pagination.current_page = 1

      this.getSubscriptions()
    }, 1000),
    onPageChanged (page) {
      this.pagination.current_page = page

      this.getSubscriptions()
    }
  }
}
</script>
