<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">{{ role.display_name }} Permissions</h1>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <div v-for="(group, key) in permissionsGroup" :key="key" class="mb-10">
        <h3><i class="el-icon-unlock mr-1"></i> {{ group.name.toUpperCase() }}</h3>
        <div
          v-for="permission in group.permissions"
          :key="permission.id"
          class="my-1 p-2">
          <label class="block flex items-center cursor-pointer">
            <input v-model="input.permissions" type="checkbox" :value="permission.id">
            <span class="mx-3">{{ permission.display_name }}</span>
            <span class="text-gray-600">{{ permission.description }}</span>
          </label>
        </div>
      </div>
      <div class="mt-10 flex justify-end">
        <router-link :to="{ name: 'role__list' }" tag="a" class="button-outline-primary py-2 px-10 rounded-full text-sm mr-5">Cancel</router-link>
        <button class="button-primary py-2 px-10 rounded-full text-sm" @click="update">Update</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RolePermission',

  data () {
    return {
      role: {},
      permissionsGroup: [],
      input: {
        permissions: []
      }
    }
  },

  async created () {
    this.__showPageLoading()

    await this.getPermissions()
    await this.findRole()

    this.__hidePageLoading()
  },

  methods: {
    async getPermissions () {
      try {
        const res = await this.$http.auth.get('/permissions/groups')

        this.permissionsGroup = res.data
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async findRole () {
      try {
        const res = await this.$http.auth.get(`/roles/${this.$route.params.id}`)

        this.role = res.data
        this.input.permissions = res.data.permissions.map(i => i.id)
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async update () {
      this.__showPageLoading()

      try {
        const res = await this.$http.auth.put(`/roles/${this.$route.params.id}/permissions`, this.input)

        this.__hidePageLoading()

        this.__httpSuccess(this, res.data)
      } catch (err) {
        this.__hidePageLoading()

        this.__httpError(this, err)
      }
    }
  }
}
</script>
