<template>
    <span :class="[active ? 'bg-green-400' : 'bg-red-400']" class="py-1 px-3 rounded-full text-white text-xs">{{ active ? 'ACTIVE' : 'INACTIVE' }}</span>
</template>

<script>
export default {
    props: {
        active: {
            type: Number,
            required: true
        }
    }
}
</script>
