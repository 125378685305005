import Vue from 'vue'
import Accounting from 'accounting'

Vue.mixin({
  methods: {
    __httpError (component, err) {
      let message = err.message

      if (err.response) {
        message = `${err.response.statusText} [${err.response.status}]`

        if (err.response.data) {
          if (err.response.headers['content-type'] === 'application/json') {
            message = err.response.data.error
          }
        }
      }

      // Handle for cancel request
      if (typeof message === 'undefined') return

      component.$notify.error({
        title: 'ERROR',
        message: message
      })

      if (err.response && err.response.status === 422) {
        let errors = err.response.data.errors
        let errorKeys = Object.keys(errors)

        errorKeys.forEach(val => {
          if (component.$validator.errors.has(val)) {
            component.$validator.errors.update({
              field: val,
              msg: errors[val][0]
            })
          } else {
            component.$validator.errors.add({
              field: val,
              msg: errors[val][0]
            })
          }
        })
      } else if (err.response && err.response.status === 401) {
        Vue.auth().destroy()

        if (component.$route.name == 'auth__login') return

        component.$router.push({
          name: 'auth__login'
        })
      } else if (err.response && err.response.status === 403) {
        component.$router.push({
          path: '/'
        })
      }
    },
    __httpSuccess (component, responseData) {
      component.$notify.success({
        title: 'SUCCESS',
        message: responseData.success
      })
    },
    __httpSuccessRedirect (component, responseData, route) {
      component.$notify.success({
        title: 'SUCCESS',
        message: responseData.success
      })

      if (typeof route === 'string') {
        component.$router.push({
          name: route
        })
      } else if (typeof route === 'object') {
        component.$router.push(route)
      }
    },
    __showPageLoading () {
      this.$store.dispatch('page/showLoading')
    },
    __hidePageLoading () {
      this.$store.dispatch('page/hideLoading')
    },
    __idrCurrency (value) {
      return Accounting.formatMoney(value, 'Rp. ', 2, '.', ',')
    }
  }
})
