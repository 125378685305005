<template>
  <div>
    <admin-nav></admin-nav>
    <div class="container mx-auto py-40">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import AdminNav from './AdminNav'

export default {
  components: {
    AdminNav
  },

  created () {
    this.$store.dispatch('getUser')
  }
}
</script>
