<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">{{ page.title }}</h1>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <form>
        <div class="form-item">
          <label class="form-item__label">Code</label>
          <el-input
            v-model="input.code"
            name="code"
            v-validate="rules.code"
            placeholder="Code">
          </el-input>
          <p v-if="errors.has('code')" class="form-item__error">{{ errors.first('code') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Name</label>
          <el-input
            v-model="input.name"
            name="name"
            v-validate="rules.name"
            placeholder="Name">
          </el-input>
          <p v-if="errors.has('name')" class="form-item__error">{{ errors.first('name') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Description</label>
          <el-input
            v-model="input.description"
            name="description"
            v-validate="rules.description"
            placeholder="Description"
            type="textarea"
            rows="10">
          </el-input>
          <p v-if="errors.has('description')" class="form-item__error">{{ errors.first('description') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Time</label>
          <div>
            <el-date-picker
              type="datetimerange"
              v-model="input.time"
              value-format="yyyy-MM-dd HH:mm:ss"
              name="time"
              v-validate="rules.time"
              start-placeholder="Start time"
              end-placeholder="End time">
            </el-date-picker>
          </div>
          <p v-if="errors.has('time')" class="form-item__error">{{ errors.first('time') }}</p>
          <p v-if="errors.has('start_time')" class="form-item__error">{{ errors.first('start_time') }}</p>
          <p v-if="errors.has('end_time')" class="form-item__error">{{ errors.first('end_time') }}</p>
        </div>
        <div class="form-item">
          <div class="flex justify-between">
            <div>
              <label class="form-item__label">File</label>
              <div>
                <input type="file" name="file" ref="file" accept="application/pdf,image/jpeg,image/jpg,image/png">
              </div>
              <p v-if="errors.has('file')" class="form-item__error">{{ errors.first('file') }}</p>
            </div>
            <div v-if="page.editing">
              <label class="form-item__label">Current file</label>
              <div>
                <a class="text-pink-500" :href="`${baseURL}/storage/images/promotion/${input.file}`" target="_blank">{{ input.file }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <label class="form-item__label">Merchant Branch</label>
          <el-autocomplete
            class="inline-input"
            v-model="input.merchantBranchString"
            name="merchant_branch"
            v-validate="rules.merchantBranch"
            :fetch-suggestions="fetchMerchantBranches"
            placeholder="Type to search..."
            @select="onMerchantBranchSelected">
          </el-autocomplete>
          <p v-if="errors.has('merchant_branch')" class="form-item__error">{{ errors.first('merchant_branch') }}</p>
        </div>
      </form>
      <div class="mt-10 flex items-center" :class="[this.page.editing ? 'justify-between' : 'justify-end']">
        <div v-if="this.page.editing">
          <el-switch
            v-model="input.active"
            :active-value="1"
            :inactive-value="0"
            active-color="#ed64a6">
          </el-switch>
        </div>
        <div>
          <router-link :to="{ name: 'promotion__list' }" tag="a" class="button-outline-primary py-2 px-10 rounded-full text-sm mr-5">Cancel</router-link>
          <button class="button-primary py-2 px-10 rounded-full text-sm" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import { baseURL } from '@/lib/http'

export default {
  data () {
    return {
      page: {
        title: 'Create Promotion',
        params: {},
        editing: false
      },
      input: {
        code: '',
        name: '',
        description: '',
        time: [],
        active: 1,
        merchantBranch: null,
        merchantBranchString: ''
      },
      rules: {
        code: 'required',
        name: 'required',
        description: 'required',
        time: 'required',
        merchantBranch: 'required'
      },
      baseURL: baseURL
    }
  },

  async created () {
    this.__showPageLoading()

    if (this.$route.name === 'promotion__edit') {
      this.page.title = 'Edit Promotion'
      this.page.params = this.$route.params
      this.page.editing = true

      await this.findPromotion()
    }

    this.__hidePageLoading()
  },

  methods: {
    fetchMerchantBranches: _.debounce(async function (queryString, cb) {
      try {
        const res = await this.$http.auth.get('merchant-branches', {
          params: {
            search: queryString
          }
        })

        const items = res.data.data.map(item => {
          return { value: item.name, id: item.id }
        })

        cb(items)
      } catch (err) {
        this.__httpError(this, err)

        cb([])
      }
    }, 1000),
    async findPromotion () {
      try {
        const res = await this.$http.auth.get(`/promotions/${this.page.params.id}`)

        this.input.code = res.data.code
        this.input.name = res.data.name
        this.input.description = res.data.description
        this.input.time = [res.data.start_time, res.data.end_time]
        this.input.file = res.data.file
        this.input.active = res.data.active
        this.input.merchantBranch = res.data.merchant_branch.id
        this.input.merchantBranchString = res.data.merchant_branch.name
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async create () {
      try {
        const data = this.createFormData()

        const res = await this.$http.auth.post('/promotions', data)

        this.__httpSuccessRedirect(this, res.data, 'promotion__list')
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async update () {
      try {
        const data = this.createFormData()

        const res = await this.$http.auth.post(`/promotions/${this.page.params.id}`, data)

        this.__httpSuccessRedirect(this, res.data, 'promotion__list')
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    save () {
      this.$validator.validate().then(async valid => {
        if (!valid) return false 

        this.__showPageLoading()

        if (this.page.editing) {
          await this.update()
        } else {
          await this.create()
        }

        this.__hidePageLoading()
      })
    },
    createFormData () {
        let data = new FormData;

        data.append('code', this.input.code)
        data.append('name', this.input.name)
        data.append('description', this.input.description)
        data.append('start_time', this.input.time[0])
        data.append('end_time', this.input.time[1])
        data.append('file', this.$refs.file.files[0])
        data.append('active', this.input.active)
        data.append('merchant_branch', this.input.merchantBranch)

        return data
    },
    onMerchantBranchSelected (item) {
      this.input.merchantBranch = item.id
    }
  }
}
</script>