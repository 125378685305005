<template>
  <div class="bg-white shadow-md w-full fixed z-10">
    <div class="container mx-auto">
      <nav>
        <div class="flex justify-between py-5 px-2">
          <a href="#">CMS</a>
          <ul>
            <dropdown position="right">
              <span>
                {{ $store.state.user.name }}
                <i class="text-sm el-icon-arrow-down"></i>
              </span>
              <ul slot="menu" class="text-gray-600">
                <li class="py-2 px-3">
                  <a class="block" href="#" @click.prevent="">Account</a>
                </li>
                <li class="py-2 px-3">
                  <a class="block" href="#" @click.prevent="logout">Sign Out</a>
                </li>
              </ul>
            </dropdown>
          </ul>
        </div>
        <div>
          <ul class="flex font-medium text-gray-600">
            <template v-for="(m, mKey) in menu">
              <dropdown v-if="m.children" :key="mKey" class="mr-5">
                {{ m.title }}
                <ul slot="menu">
                  <li v-for="(c, cKey) in m.children" :key="cKey" class="py-2 px-3">
                    <router-link :to="{ name: c.route }">{{ c.title }}</router-link>
                  </li>
                </ul>
              </dropdown>
              <li v-else :key="mKey" class="mr-5 py-3 px-2">
                <router-link :to="{ name: m.route }">{{ m.title }}</router-link>
              </li>
            </template>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown'

export default {
  name: 'AdminNav',

  components: {
    Dropdown
  },

  data () {
    return {
      menu: []
    }
  },

  created () {
    this.getMenu()
  },

  methods: {
    async getMenu () {
      try {
        const res = await this.$http.auth.get('/app/menu')

        this.menu = res.data
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    logout () {
      this.$auth.destroy()

      this.$store.dispatch('clearUser')

      this.$router.push({ name: 'auth__login' })
    }
  }
}
</script>