import List from '@/views/chat-intent/Index'
import Create from '@/views/chat-intent/Create'
import Config from '@/views/chat-intent/Config'

export default [
  {
    path: '/bot/chat-intents',
    name: 'chat_intent__list',
    component: List
  },
  {
    path: '/bot/chat-intents/create',
    name: 'chat_intent__create',
    component: Create
  },
  {
    path: '/bot/chat-intents/:id/edit',
    name: 'chat_intent__edit',
    component: Create
  },
  {
    path: '/bot/chat-intents/:id/config',
    name: 'chat_intent__config',
    component: Config
  }
]
