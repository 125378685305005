import Main from '@/views/response-message/Index'
import Create from '@/views/response-message/Create'

export default [
  {
    path: '/bot/response-messages',
    name: 'response_message__list',
    component: Main
  },
  {
    path: '/bot/response-messages/create',
    name: 'response_message__create',
    component: Create
  },
  {
    path: '/bot/response-messages/:id/edit',
    name: 'response_message__edit',
    component: Create
  },
]
