import Main from '@/views/subscription/Index'
import Create from '@/views/subscription/Create'
import Show from '@/views/subscription/Show'

export default [
  {
    path: '/subscriptions',
    name: 'subscription__list',
    component: Main
  },
  {
    path: '/subscriptions/:id',
    name: 'subscription__show',
    component: Show
  }
]
