<template>
  <div class="min-h-screen flex justify-center items-center">
    <div class="w-full md:w-1/2 lg:w-1/3 xl:max-w-md m-5 bg-white shadow-lg pt-20 px-10 pb-10 rounded">
      <div class="text-center">
        <h1 class="font-semibold text-3xl">Welcome</h1>
      </div>
      <div class="my-5">
        <input
          v-model="input.email"
          v-validate="'required'"
          name="email"
          class="w-full py-2 leading-relaxed border-solid border-b-2 hover:outline-none focus:outline-none focus:border-pink-500"
          placeholder="Email"
          autocomplete="off"
          type="text">
        <p v-if="errors.has('email')" class="mt-2 text-sm text-red-500">{{ errors.first('email') }}</p>
      </div>
      <div class="my-5">
        <input
          v-model="input.password"
          v-validate="'required'"
          name="password"
          class="w-full py-2 leading-relaxed border-solid border-b-2 hover:outline-none focus:outline-none focus:border-pink-500"
          placeholder="Password"
          autocomplete="off"
          type="password">
        <p v-if="errors.has('password')" class="mt-2 text-sm text-red-500">{{ errors.first('password') }}</p>
      </div>
      <div class="mt-20">
        <button class="button-primary w-full py-2 leading-loose rounded-full font-semibold uppercase" :disabled="action.loading" @click="login">
          <button-loading v-if="action.loading"></button-loading>
          <span v-else>Sign In</span>
        </button>
      </div>
      <p class="mt-10 text-center text-gray-500">
        <a href="">Forgot Password?</a>
      </p>
    </div>
  </div>
</template>

<script>
import ButtonLoading from '@/components/loading/ButtonLoading'

export default {
  name: 'Login',

  components: {
    ButtonLoading
  },

  data () {
    return {
      action: {
        loading: false
      },
      input: {
        email: '',
        password: ''
      }
    }
  },

  methods: {
    login () {
      this.$validator.validate().then(async valid => {
        if (!valid) return false

        this.action.loading = true

        try {
          const res = await this.$http.guest.post('/login', this.input)

          this.$auth.store(res.data.data)

          this.$router.push({ name: 'user__list' })
        } catch (err) {
          this.action.loading = false

          this.__httpError(this, err)
        }
      })
    }
  }
}
</script>