import Main from '@/views/hot-deal/Index'
import Create from '@/views/hot-deal/Create'

export default [
  {
    path: '/hot-deals',
    name: 'hot_deal__list',
    component: Main
  },
  {
    path: '/hot-deals/create',
    name: 'hot_deal__create',
    component: Create
  },
  {
    path: '/hot-deals/:id/edit',
    name: 'hot_deal__edit',
    component: Create
  },
]
