import Vue from 'vue'
import Vuex from 'vuex'

import {
  UPDATE_USER,
  CLEAR_USER
} from './mutation-types'

import page from './modules/page'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    page
  },

  state: {
    user: {}
  },
  mutations: {
    [UPDATE_USER] (state, user) {
      state.user = user
    },
    [CLEAR_USER] (state) {
      state.user = {}
    }
  },
  actions: {
    async findUser ({ commit }) {
      const res = await Vue.http().auth.get('/user')

      if (res) {
        commit(UPDATE_USER, res.data)
      }
    },
    async getUser ({ state, dispatch }) {
      if (Object.keys(state.user).length === 0) {
        await dispatch('findUser')
      }

      return state.user
    },
    clearUser ({ commit }) {
      commit(CLEAR_USER)
    }
  }
})

export default store
