import Vue from 'vue'
import axios from 'axios'

export let baseURL = 'http://localhost:8000'

if (window.APP && window.APP.url) {
  baseURL = window.APP.url
}

const apiURL = `${baseURL}/api`

Vue.http = () => {
  const http = axios.create({
    baseURL: apiURL
  })

  const guest = http

  const auth = http

  const token = Vue.auth().token()

  auth.defaults.headers['Authorization'] = `Bearer ${token}`

  auth.interceptors.response.use(res => {
    return res
  }, err => {
    return Promise.reject(err)
  })

  return { guest, auth }
}

Object.defineProperties(Vue.prototype, {
  $axios: {
    get () { return axios }
  },
  $http: {
    get () { return Vue.http() }
  }
})
