<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">{{ page.title }}</h1>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <form>
        <div class="form-item">
          <label class="form-item__label">Parent</label>
          <el-select v-model="input.parent" name="parent">
            <el-option label="None" :value="null"></el-option>
            <el-option
              v-for="intent in intens"
              :key="intent.id"
              :label="`${intent.code} ${intent.name}`"
              :value="intent.id">
            </el-option>
          </el-select>
          <p class="form-item__error">{{ errors.first('parent') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Code</label>
          <el-input v-model="input.code" name="code" v-validate="rules.code" placeholder="Code"></el-input>
          <p class="form-item__error">{{ errors.first('code') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Name</label>
          <el-input v-model="input.name" name="name" v-validate="rules.name" placeholder="Name"></el-input>
          <p class="form-item__error">{{ errors.first('name') }}</p>
        </div>
      </form>
      <div class="mt-10 flex items-center" :class="[this.page.editing ? 'justify-between' : 'justify-end']">
        <div v-if="this.page.editing">
          <el-switch
            v-model="input.active"
            :active-value="1"
            :inactive-value="0"
            active-color="#ed64a6">
          </el-switch>
        </div>
        <div>
          <router-link :to="{ name: 'chat_intent__list' }" tag="a" class="button-outline-primary py-2 px-10 rounded-full text-sm mr-5">Cancel</router-link>
          <button class="button-primary py-2 px-10 rounded-full text-sm" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      page: {
        title: 'Create Chat Intent',
        params: {},
        editing: false
      },
      intens: [],
      input: {
        parent: null,
        code: '',
        name: '',
        active: 1
      },
      rules: {
        code: 'required',
        name: 'required'
      },
      filter: {
        except: []
      }
    }
  },

  async created () {
    this.__showPageLoading()

    if (this.$route.name === 'chat_intent__edit') {
      this.page.title = 'Edit Chat Intent'
      this.page.params = this.$route.params
      this.page.editing = true

      this.filter.except = [parseInt(this.$route.params.id)]

      await this.findIntent()
    }

    await this.getIntents()

    this.__hidePageLoading()
  },

  methods: {
    async getIntents () {
      try {
        const res = await this.$http.auth.get('/bot/chat-intents/all', {
          params: {
            except: this.filter.except
          }
        })

        this.intens = res.data
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async findIntent () {
      try {
        const res = await this.$http.auth.get(`/bot/chat-intents/${this.page.params.id}`)

        this.input.parent = res.data.parent_id
        this.input.code = res.data.code
        this.input.name = res.data.name
        this.input.active = res.data.active
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async create () {
      try {
        const res = await this.$http.auth.post('/bot/chat-intents', this.input)

        this.__httpSuccessRedirect(this, res.data, {
          name: 'chat_intent__config',
          params: { id: res.data.data.id }
        })
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async update () {
      try {
        const res = await this.$http.auth.put(`/bot/chat-intents/${this.page.params.id}`, this.input)

        this.__httpSuccessRedirect(this, res.data, 'chat_intent__list')
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    save () {
      this.$validator.validate().then(async valid => {
        if (!valid) return false 

        this.__showPageLoading()

        if (this.page.editing) {
          await this.update()
        } else {
          await this.create()
        }

        this.__hidePageLoading()
      })
    }
  }
}
</script>