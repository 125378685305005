<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">{{ page.title }}</h1>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <form>
        <div class="form-item">
          <label class="form-item__label">Type</label>
          <el-select v-model="input.type" name="text" v-validate="rules.message">
            <el-option label="Text" value="text"></el-option>
          </el-select>
          <p class="form-item__error">{{ errors.first('type') }}</p>
        </div>
        <div class="form-item">
          <label class="form-item__label">Message</label>
          <el-input v-model="input.message" name="message" v-validate="rules.message" placeholder="Message"></el-input>
          <p class="form-item__error">{{ errors.first('message') }}</p>
        </div>
      </form>
      <div class="mt-10 flex items-center" :class="[this.page.editing ? 'justify-between' : 'justify-end']">
        <div v-if="this.page.editing">
          <el-switch
            v-model="input.active"
            :active-value="1"
            :inactive-value="0"
            active-color="#ed64a6">
          </el-switch>
        </div>
        <div>
          <router-link :to="{ name: 'entry_message__list' }" tag="a" class="button-outline-primary py-2 px-10 rounded-full text-sm mr-5">Cancel</router-link>
          <button class="button-primary py-2 px-10 rounded-full text-sm" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      page: {
        title: 'Create Subscription',
        params: {},
        editing: false
      },
      input: {
        type: 'text',
        message: '',
        active: 1
      },
      rules: {
        type: 'required',
        message: 'required'
      }
    }
  },

  async created () {
    this.__showPageLoading()

    if (this.$route.name === 'subscription__edit') {
      this.page.title = 'Edit Subsccription'
      this.page.params = this.$route.params
      this.page.editing = true

      await this.findSubscription()
    }

    this.__hidePageLoading()
  },

  methods: {
    async findSubscription () {
      try {
        const res = await this.$http.auth.get(`/subscriptions/${this.page.params.id}`)

        this.input.type = res.data.type
        this.input.message = res.data.message
        this.input.active = res.data.active
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async create () {
      try {
        const res = await this.$http.auth.post('/subscriptions', this.input)

        this.__httpSuccessRedirect(this, res.data, 'subscription__list')
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    async update () {
      try {
        const res = await this.$http.auth.put(`/subscriptions/${this.page.params.id}`, this.input)

        this.__httpSuccessRedirect(this, res.data, 'subscription__list')
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    save () {
      this.$validator.validate().then(async valid => {
        if (!valid) return false 

        this.__showPageLoading()

        if (this.page.editing) {
          await this.update()
        } else {
          await this.create()
        }

        this.__hidePageLoading()
      })
    }
  }
}
</script>