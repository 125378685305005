import {
  SHOW_LOADING,
  HIDE_LOADING
} from './mutation-types'

const namespaced = true

const state = {
  loading: false
}

const getters = {
  pageIsLoading: state => state.loading
}

const mutations = {
  [SHOW_LOADING] (state) {
    state.loading = true
  },
  [HIDE_LOADING] ( state) {
    state.loading = false
  }
}

const actions = {
  showLoading ({ commit }) {
    commit(SHOW_LOADING)
  },
  hideLoading ({ commit }) {
    commit(HIDE_LOADING)
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
