<template>
  <div>
    <div class="mb-5 flex justify-between">
      <h1 class="text-2xl">Chat Intents</h1>
      <router-link
        :to="{ name: 'chat_intent__create' }"
        tag="button"
        class="button-primary py-2 px-10 rounded-full text-sm">
        New
      </router-link>
    </div>
    <div class="bg-white shadow-md rounded my-5 p-5 flex justify-between">
      <div class="w-2/12">
      </div>
      <div class="w-3/12">
        <el-input
          v-model="filter.search"
          placeholder="Search..."
          prefix-icon="el-icon-search"
          :clearable="true" @input="onSearch">
        </el-input>
      </div>
    </div>
    <div class="bg-white shadow-md rounded p-5">
      <div>
        <el-table :data="intents">
          <el-table-column
            label="Fullname">
            <template slot-scope="scope">
              <span v-html="scope.row.fullname"></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Actions"
            width="200"
            align="center">
            <template slot-scope="scope">
              <router-link
                :to="{ name: 'chat_intent__config', params: { id: scope.row.id } }"
                tag="button"
                class="border-solid border border-gray-300 px-5 rounded text-sm focus:outline-none">
                Config
              </router-link>
              <router-link
                :to="{ name: 'chat_intent__edit', params: { id: scope.row.id } }"
                tag="button"
                class="ml-2 border-solid border border-gray-300 px-5 rounded text-sm focus:outline-none">
                Edit
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center pt-5">
          <el-pagination
            layout="prev, pager, next"
            :current-page="pagination.current_page"
            :page-size="pagination.per_page"
            :total="pagination.total"
            @current-change="onPageChanged">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ChatIntentList',

  data () {
    return {
      intents: [],
      pagination: {
        current_page: 1,
        per_page: 25,
        total: 0
      },
      filter: {
        sort: 'code,asc',
        search: ''
      }
    }
  },

  async created () {
    this.__showPageLoading()

    await this.getIntents()

    this.__hidePageLoading()
  },

  methods: {
    async getIntents () {
      try {
        const res = await this.$http.auth.get('/bot/chat-intents', {
          params: {
            page: this.pagination.current_page,
            search: this.filter.search,
            sort: this.filter.sort.split(',')
          }
        })

        this.intents = res.data.data
        this.pagination = res.data.pagination
      } catch (err) {
        this.__httpError(this, err)
      }
    },
    onSort() {
      this.pagination.current_page = 1

      this.getIntents()
    },
    onSearch: _.debounce(function () {
      this.pagination.current_page = 1

      this.getIntents()
    }, 1000),
    onPageChanged (page) {
      this.pagination.current_page = page

      this.getIntents()
    }
  }
}
</script>
